import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
 @Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
    formData = {
        mid: 'B10002',
        grossAmount: '12.00',
        netAmount: '12.00',
        rrn: '1234567987654321',
        surl: 'https://pguat.zavepay.com/pg-wrapper-checkout/transaction/frm/v1/txnfrmResponse',
        furl: 'https://pguat.zavepay.com/pg-wrapper-checkout/transaction/frm/v1/txnfrmResponse',
        orderNo: 'O12345',
        metaInfo: '',
        mobile: '8369032578',
        email: 'test@gmail.com',
        user: 'test@gmail.com',
        name: 'Mike',
        udf1: 'udf1',
        udf2: 'udf2',
        udf3: 'udf3',
        udf4: 'udf4',
        udf5: 'udf5',
        udf6: 'udf6',
        udf7: 'udf7',
        udf8: 'udf8',
        udf9: 'udf9',
        udf10: 'udf10',
        checksum: 'fcaea16f275d4d97eab6fc134e0b9d830d3e800233f5b3e7c1e2df8f7f558f9e3e17a93c1a265fe253348e8e523253d0ba62426c425cecc2fb2d8905424d831d',
      };
      paymentForm: FormGroup;

      constructor(private fb: FormBuilder,private http: HttpClient) {}

       
    ngOnInit(): void { this.paymentForm = this.fb.group({
        mid: ['B10002', Validators.required],
        grossAmount: ['12.00', Validators.required],
        netAmount: ['12.00', Validators.required],
        rrn: ['1234567987654321', Validators.required],
        surl: ['https://pguat.zavepay.com/pg-wrapper-checkout/transaction/frm/v1/txnfrmResponse'],
        furl: ['https://pguat.zavepay.com/pg-wrapper-checkout/transaction/frm/v1/txnfrmResponse'],
        orderNo: ['O12345', Validators.required],
        metaInfo: [''],
        mobile: ['8369032578', Validators.required],
        email: ['test@gmail.com', Validators.required],
        user: ['test@gmail.com', Validators.required],
        name: ['Mike', Validators.required],
        udf1: ['udf1'],
        udf2: ['udf2'],
        udf3: ['udf3'],
        udf4: ['udf4'],
        udf5: ['udf5'],
        udf6: ['udf6'],
        udf7: ['udf7'],
        udf8: ['udf8'],
        udf9: ['udf9'],
        udf10: ['udf10'],
        checksum: ['fcaea16f275d4d97eab6fc134e0b9d830d3e800233f5b3e7c1e2df8f7f558f9e3e17a93c1a265fe253348e8e523253d0ba62426c425cecc2fb2d8905424d831d'],
      });}
    submitForm(){
    //     this.http.post('https://pguat.zavepay.com/pg-wrapper-checkout/checkout/initiate/paymentRequest', this.formData).subscribe((response:any) => {debugger
    //   console.log('Payment Response:', response);
    //   window.location.href=response?.redirectURL

    // });
       document.getElementById('submitbtn')?.click()
    }
    onSubmit(): void {debugger
        localStorage.setItem('url','https://pguat.zavepay.com/payments/#/payment-gateway/088f48f8-f58e-41f2-b83d-7a3aebaf0115/checkout')
        document.getElementById('seamlessBtn')?.click()
        

        // Prevent default form submission behavior (no page reload)
        if (this.paymentForm.valid) {
          const formData = this.paymentForm.value;
          console.log('Captured Form Data:', formData);
    
          // Send the form data using HTTP request (for example using HttpClient)
          this.http.post('https://pguat.zavepay.com/pg-wrapper-checkout/checkout/payment/request', formData)
            .subscribe((response:any) => {
              console.log('Server Response:', response);
              localStorage.setItem('JSONData',JSON.stringify(response))
        //  localStorage.setItem('url',response?.redirectURL)
         document.getElementById('seamlessBtn')?.click()
              // Optionally, handle redirection or other logic here based on the response
            }, error => {
              console.error('Error:', error);
            });
        }
      }
//     submitSeamlessForm(){debugger
//         // this.http.post('https://pguat.zavepay.com/pg-wrapper-checkout/checkout/initiate/paymentRequest', this.formData).subscribe((response:any) => {debugger
//         // console.log('Payment Response:', response);
//         // localStorage.setItem('url',response?.redirectURL)
//         // //   window.location.href=response?.redirectURL
    
//         // });
//         const url = "https://pguat.zavepay.com/pg-wrapper-checkout/checkout/initiate/paymentRequest";

// // Make the POST request
// fetch(url, {
//     method: 'POST',
//     headers: {
//         'Content-Type': 'application/x-www-form-urlencoded', // Form data
//     },
//     body: new URLSearchParams(this.formData), // Converts the formData object to the query string format
// })
//     .then(response => response.json()) // Convert the response to JSON
//     .then(data => {debugger

//         console.log('Success:', data); // Handle success
//     })
//     .catch((error) => {
//         console.error('Error:', error); // Handle error
//     });
//           // document.getElementById('submitbtn')?.click()
//         }
    teamSlides: OwlOptions = {
		loop: true,
        margin: 20,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    }
    clientWrap: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		mouseDrag: true,
		items:1,
		dots: false,
		autoHeight: true,
		autoplay: true,
		smartSpeed: 800,
		autoplayHoverPause: true,
		center: false,
		responsive:{
			0:{
				items:1,
				margin: 10,
			},
			576:{
				items:1,
			},
			768:{
				items:2,
				margin: 20,
			},
			992:{
				items:2,
			},
			1200:{
				items:2,
			}
		}
    }

    // Accordion
    accordionItems = [
        {
            title: 'Great Understanding',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        },
        {
            title: 'Update Technology',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        },
        {
            title: 'Experienced Team',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        },
        {
            title: 'Best Quality Service',
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt sit amet consectetur adipiscing.`,
            open: false
        }
    ];
    selectedItem : any = null;
    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }
    
    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

}