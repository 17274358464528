<!-- <div class="iframe-container" >
         <iframe src="/payments/assets/index1.html" style="width: 100%;height: 100vh;" ></iframe>
    </div> -->
    
    <header class="sticky-top bg-white shadow-sm border outerNav"  style="height: 12vh;">
        <div class="container">
            <div class="row ">
                <!-- Left Section: Logo -->
                <div class="col-4 mt-3 col-md-2 text-start">
                    <a href="#" class="logo">
                        <img id="imgSource" class="imgHeader" src="/assets/images/logo-view.svg" alt="Logo" style="height: 35px;margin-top: 0%;">
                        <!-- <img id="imgSource" class="imgHeader" src="/assets/images/logo-view.svg" alt="Logo" style="height: 35px;margin-top: 3%;"> -->
                     </a>
                </div> 
            </div>
        </div>
    </header>
    <header class="sticky-top bg-white shadow-sm border responsive-div"  style="height: 10vh;display: none;">
        <div class="container">
            <div class="row ">
                <!-- Left Section: Logo -->
                <div class="col-4 mt-3 col-md-2 text-start">
                    <a href="#" class="logo">
                        <img id="imgSource" class="imgHeader" src="/payments/assets/images/logo-view.svg" alt="Logo" style="height: 35px;margin-top: 0%;">
                        <!-- <img id="imgSource" class="imgHeader" src="/assets/images/logo-view.svg" alt="Logo" style="height: 35px;margin-top: 8%;"> -->
                     </a>
                </div> 
            </div>
        </div>
    </header>
<div class="container mt-5" >
    <div class="col-md-12 col-lg-8 col-xl-6 mb-4 " style="margin-left: auto;margin-right: auto;" >
    <div class=" bg-white  p-4">
    <div class="text-left p-1 ml-2" [style.display]="status === '200' ? 'block' : 'none'">
        <!-- <i class="far fa-credit- fa-2x text-success"></i> -->
        <img src="/payments/assets/images/success.png" alt="Logo" >

        <h5 class="fontBody  mt-2" style="color: #1bb24b;">Transaction Successful!</h5>
        <p style="color: black;" class="small fontBody">Thank you! <br />Your payment of  Rs.{{ transactionData?.netAmount }} has been received by <strong style="color: #000;
             font-size: 18px;
             font-weight: 700;
            line-height: normal;">ZavePay Technologies</strong>.
            <br /><span style="color: #818181;
             font-size: 16px;
             font-weight: 400;
            line-height: normal;">Transaction ID: {{ transactionData?.txnId.toUpperCase() }}</span></p>
    </div>
    <div class="text-left p-1 ml-2" [style.display]="(status === '220'||status === '223'||status === '224'||status === '222'||status === '225') ? 'block' : 'none'">
        <!-- <i class="far fa-credit- fa-ban fa-2x text-danger"></i> -->
        <img src="/payments/assets/images/failed.png" alt="Logo"  >

        <h5 class="text-danger mt-2 fontBody">Transaction Failed!</h5>
        <p style="color: black;" class="small fontBody">Hey there! <br />something went wrong <br />
           Please update or check your payment method to continue .
            <br /></p>
    </div>
    <div class="text-left p-1 ml-2" [style.display]="status === '210' ? 'block' : 'none'">
        <!-- <i class="fas fa-exclamation-triangle fa-2x text-warning"></i> -->
        <img src="/payments/assets/images/pending.png" alt="Logo" height="60px!important" >
        <h5 class=" fontBody mt-2" style="color: #c29712;">Transaction Pending!</h5>
        <p style="color: black;" class="small fontBody">Hey there!<br /> Please <strong>do not make</strong> this payment again.
            <br /> Please wait for the transaction to complete/fail</p>
    </div>
    <div class="container mt-2">
        <!-- <h3>Transaction Details</h3> -->
        <!-- Make table scrollable on smaller screens -->
        <div class="table-responsive">
        <table class="table border">
                <!-- <thead  > -->
                    <!-- <tr>
                        <th>Mid</th>
                        <th>{{ transactionData?.mid }}</th>
                    </tr> -->
                    <!-- <tr>
                        <th>Gross Amount</th>
                        <th>{{ transactionData?.grossAmount }}</th>
                    </tr> -->
                <!-- </thead> -->
                <tbody> 
                   
                    <tr>
                        <td class="theaders">Net Amount</td>
                        <td class="tbody">{{ transactionData?.netAmount }}</td>
                    </tr>
                    <!-- <tr>
                        <td>RRN</td>
                        <td>{{ transactionData?.rrn }}</td>
                    </tr> -->
                    <tr>
                        <td class="theaders">Order No</td>
                        <td class="tbody">{{ transactionData?.orderNo }}</td>
                    </tr>
                    <tr>
                        <td class="theaders">Mobile</td>
                        <td class="tbody">{{ transactionData?.mobile }}</td>
                    </tr>
                    <tr>
                        <td class="theaders">Email</td>
                        <td class="tbody">{{ transactionData?.email }}</td>
                    </tr>
                    <tr>
                        <td class="theaders">User</td>
                        <td class="tbody">{{ transactionData?.user }}</td>
                    </tr>
                    <tr>
                        <td class="theaders">Name</td>
                        <td class="tbody">{{ transactionData?.name }}</td>
                    </tr>
                    <!-- <tr>
                        <td>Txn ID</td>
                        <td>{{ transactionData?.txnId }}</td>
                    </tr> -->
                    <tr>
                        <td class="theaders">Txn Status</td>
                        <td class="tbody">{{ transactionData?.txnStatus }}</td>
                    </tr>
                    <!-- <tr>
                        <td>Status Code</td>
                        <td>{{ transactionData?.statusCode }}</td>
                    </tr> -->
                    <!-- <tr>
                        <td>Error Code</td>
                        <td>{{ transactionData?.errorCode }}</td>
                    </tr> -->
                    <!-- <tr>
                        <td>Bank Ref No</td>
                        <td>{{ transactionData?.bankRefNO }}</td>
                    </tr> -->
                    <tr>
                        <td class="theaders">Payment Mode</td>
                        <td class="tbody">{{ transactionData?.paymentMode=='NB'?'Net Banking':( transactionData?.paymentMode=='CC'?'Credit Card':transactionData?.paymentMode=='DC'?'Debit Card':transactionData?.paymentMode) }}</td>
                    </tr>
                    <!-- <tr>
                        <td>Checksum</td>
                        <td>{{ transactionData?.checksum }}</td>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </div>

    <!-- Home Button Section -->
    <div class="text-center mt-4">
        <p class="tbody">Click to Home to return</p>
        <button class="btn btn-outline-success" routerLink="/" class="tbody">Home</button>
    </div>
    </div>
    </div>
</div>